<template>
    <div class="result">
		<div class="result-main">
			<div class="result-title">
			    <img class="h2" src="@/assets/img/cxds/title_h2_2023.png">
				<img class="h4" src="@/assets/img/cxds/title_h4_2023.png">
			    <h3>荣誉榜单</h3>
			</div>
			<div class="result-content">
				<div class="li">
					<!-- 第一 -->
					<div class="ranking">
						<div class="head">
							<div class="head-title">
								<img src="@/assets/img/cxds/ranking_1.png">
								<img src="@/assets/img/cxds/di1.png">
							</div>
							<!-- <div class="button" @click="bool_1=!bool_1">查看</div> -->
						</div>
						<div class="content" v-show="bool_1">
							<div class="item"  v-if="list[0]">
								<img :src="list[0].player_img || 'https://image.ysxxlm.cn/ysxxlm/pc/static/img/my.png'">
								<div class="player_name">{{list[0].player_name}}</div>
								<div class="player_hospital_name">{{list[0].player_hospital_name}}</div>
							</div>
						</div>
					</div>
					<!-- 第二 -->
					<div class="ranking">
						<div class="head">
							<div class="head-title">
								<img src="@/assets/img/cxds/ranking_2.png">
								<img src="@/assets/img/cxds/di2.png">
							</div>
							<!-- <div class="button" @click="bool_2=!bool_2">查看</div> -->
						</div>
						<div class="content" v-show="bool_2">
							<div class="item"  v-if="list[1]">
								<img :src="list[1].player_img || 'https://image.ysxxlm.cn/ysxxlm/pc/static/img/my.png'">
								<div class="player_name">{{list[1].player_name}}</div>
								<div class="player_hospital_name">{{list[1].player_hospital_name}}</div>
							</div>
						</div>
					</div>
					<!-- 第三 -->
					<div class="ranking">
						<div class="head">
							<div class="head-title">
								<img src="@/assets/img/cxds/ranking_3.png">
								<img src="@/assets/img/cxds/di3.png">
							</div>
							<!-- <div class="button" @click="bool_3=!bool_3">查看</div> -->
						</div>
						<div class="content" v-show="bool_3">
							<div class="item"  v-if="list[2]">
								<img :src="list[2].player_img || 'https://image.ysxxlm.cn/ysxxlm/pc/static/img/my.png'">
								<div class="player_name">{{list[2].player_name}}</div>
								<div class="player_hospital_name">{{list[2].player_hospital_name}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
				bool_1:true,
				bool_2:true,
				bool_3:true,
				bool_4:true,
				bool_5:true,
				bool_6:true,
                title: '',
                list: []
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            getList() {
                this.$axios.post(this.apiUrl.cxdsresult,{group_id:this.$route.query.group_id}).then(res => {
                    this.list = res.data.array_data
					// for (var i = 0; i < 15; i++) {					// 	this.list.push(res.data.array_data[0])					// }
                    this.title = res.msg
                })
            },
        }
    }
</script>
<style scoped lang="scss">
	
    .result{
		// 隐藏滚动条
		// ::-webkit-scrollbar {
		// 	display: none;
		// }
		// scrollbar-width:none;
		// -ms-overflow-style:none;
        background: url("~@/assets/img/cxds/cxdsBj2023.jpg") no-repeat;
        background-size: 100% 100%;
        height: 100vh;
		box-sizing: border-box;
		overflow: auto;
		.result-main{
			padding-top: 55px;
			.result-title{
				.h2{
					display: block;
					margin: 0 auto;
				}
				h3{
					margin: 10px auto 0;
					width: 258px;
					height: 57px;
					background: #44B0F6;
					border: 0px solid #0D0509;
					border-radius: 29px;
					line-height: 57px;
					font-size: 37px;
					font-family: 楷体;
					font-weight: 400;
					color: #FDFDFD;
					text-align: center;
				}
				.h4{
					display: block;
					margin: 30px auto 50px;
				}
			}
			.result-content{
				// display: flex;
				margin-top: 120px;
				padding: 0 150px;
				height: 55vh;
				.li{
					display: flex;
					// flex-wrap: wrap;
					justify-content: space-around;
				}
				.ranking{
					display: flex;
					flex-direction: column;
					align-items: center;
					margin-bottom: 80px;
					width: 400px;
					height: 210px;
					.head{
						margin-bottom: 40px;
						.head-title{
							display: flex;
							align-items: center;
							img:nth-child(1){
								margin-right: 20px;
							}
						}
						.button{
							cursor:pointer;
							margin:10px 0 0 50px;
							width: 142px;
							height: 35px;
							background: linear-gradient(0deg, #015EEA, #00C0FA);
							border: 3px solid #FFFFFF;
							border-radius: 14px;
							line-height: 35px;
							text-align: center;
							font-size: 27px;
							font-weight: 500;
							color: #FFFFFF;
						}
					}
					.content{
						display: flex;
						flex-wrap: wrap;
						justify-content: space-around;
						.item{
							margin-left: 45px;
							color: #FDFDFD;
							text-align: center;
							width: 150px;
							img{
								width: 142px;
								height: 142px;
								border-radius: 50%;
								margin-bottom: 10px;
							}
							.player_name{
								font-weight: bold;
								font-size: 25px;
								line-height: 30px;
							}
							.player_hospital_name{
								font-size: 14px;
							}
						}
					}
				}
			}
		}

    }
</style>

